import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  ClockIcon,
  ChatAlt2Icon,
  DocumentTextIcon,
  ArchiveIcon,
  CheckIcon,
} from '@heroicons/react/outline';
import Layout from '../../components/layout';
import BenefitHero from '../../components/Benefit/BenefitHero';
import BenefitFeatures from '../../components/Benefit/BenefitFeatures';
import BenefitFeaturesImage from '../../components/Benefit/BenefitFeaturesImage';
import BenefitCta from '../../components/Benefit/BenefitCta';
import BenefitFeaturesTwoColumn from '../../components/Benefit/BenefitFeaturesTwoColumn';
import BookDemo from '../../components/BookDemo';
import { SEO } from '../../components/SEO/SEO';

const FeaturesList = [
  {
    name: 'Urenregistatie',
    description:
      'Laat je medewerkers of onderaannemer hun uren schrijven met Homigo. Zo hoef je niet meer achter papieren briefjes aan te jagen en ben je direct op de hoogte van het aantal gewerkte uren. Je kunt de uren inzien op een overzichtspagina en direct goedkeuren.',
    icon: ClockIcon,
  },
  {
    name: 'Meer/minderwerk afspraken',
    description:
      'Leg een meer- of minderwerkafspraak vast, zodat je achteraf geen discussie hebt over wat is afgesproken. Bepaal de prijs en verstuur de afspraak naar de klant. De klant kan de afspraak vervolgens digitaal accepteren.',
    icon: DocumentTextIcon,
  },
  {
    name: 'Professionele klantrelatie',
    description:
      'Klanten van onze aannemers ervaren meer rust en geven aannemers door Homigo een hogere waardering. Homigo zorgt voor een verbeterde klantrelatie door het faciliteren van heldere communicatie.',
    icon: ChatAlt2Icon,
  },
  {
    name: 'Projectarchief',
    description:
      'Archiveer je projecten met alle documentatie zodat je een dossier opbouwt per project. Zo voldoe je aan de WKB. Ook kan je gemaakte afspraken later nog terugvinden als je dat nodig hebt.',
    icon: ArchiveIcon,
  },
];

const ManagePage = ({ location }) => (
  <Layout>
    <SEO location={location} pageMetadata={{ title: 'Manage' }} />
    <BenefitHero
      heading={
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block xl:inline">Manage je projecten </span>{' '}
          <span className="block text-yellow-400 xl:inline">met gemak</span>
        </h1>
      }
      subheading="
        Manage je projecten op eenvoudige wijze door alles binnen 1 app vast te leggen."
    />

    <BenefitFeatures
      title="CHAT EN DEEL"
      heading="Iedereen op de hoogte"
      subheading="Zorg dat alle projectleden altijd op de hoogte zijn en bespaar jezelf een hoop telefoontjes, appjes en emails per dag."
      features={FeaturesList}
    />

    <BenefitFeaturesImage
      title="Als onderdeel van je dagelijkse routine"
      heading="Projectmanagement, maar dan eenvoudig."
      subheading="Meerdere projecten tegelijk managen is niet altijd makkelijk. Je hoofd zit vol en al je projectleden zijn van jou afhankelijk. Waarom maak je het jezelf niet makkelijker door overzicht en duidelijkheid te creëeren voor alle betrokkenen? Leg alles eenvoudig vast binnen één app. Zo houd je tijd over om te bouwen."
      image={
        <StaticImage
          src="../../img/manage_large.png"
          alt=""
          className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
        />
      }
    />

    <BenefitCta
      heading={
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">Meer grip op je projecten?</span>
          <span className="">Download</span>
          <span className="text-primary"> Homigo </span>
          <span className="">vandaag nog.</span>
        </h2>
      }
      subheading="Homigo is verkrijgbaar voor zowel IOS als Android en is ook te gebruiken als webversie op je computer."
    />
    <BenefitFeaturesTwoColumn
      iconOne={<CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />}
      headerOne="Structuur en eenvoud"
      paragraphOne="Weg met alle losse briefjes en een vol hoofd. Met Homigo krijg je grip op je projecten door structuur en eenvoud aan te brengen. Dit bespaart je tijd en levert je meer omzet op."
      quote="Mijn hoofd zat altijd vol. Ik had het gevoel nooit uit te staan. Nu heb ik overzicht over al mijn projectleden en weten mijn jongens waar ze aan toe zijn."
      quoteName="David Veenendaal, aannemer"
      imageOne={<StaticImage src="../../img/manage_iphone.png" alt="" className="" />}
      iconTwo={<ClockIcon className="h-6 w-6 text-white" aria-hidden="true" />}
      headerTwo="Grip op je uren"
      paragraphTwo="Als project-admin heb je een aparte pagina met het overzicht van de uren van alle projectleden. Je ziet hier alle ingediende, afgewezen en goedgekeurde uren die je eenvoudig kunt filteren. Met een druk op de knop exporteer je het urenoverzicht naar je computer of laptop."
      imageTwo={
        <StaticImage src="../../img/manage_desktop.png" alt="Customer profile user interface" />
      }
    />
    <BookDemo />
  </Layout>
);

export default ManagePage;
